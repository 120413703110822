<template>
  <b-card>
    <div>
      <b-row class="p-1">
        <!-- <div v-if="!showCreate">
          <b-button
            variant="relief-success"
            class="mr-2"
            @click="showCreate = true"
          >
            <feather-icon icon="PlusIcon" />
            Cadastrar
          </b-button>
        </div>
        <div v-else>
          <b-button
            variant="relief-danger"
            class="mr-2"
            @click="showCreate = false"
          >
            <feather-icon icon="ListIcon" />
            Listar
          </b-button>
        </div> -->
        <div v-if="!showSearch">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisar"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>

      <div v-show="!showCreate">
        <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
            striped
            stacked="md"
            noDataText="Você ainda não possui clientes cadastrados."
            class="mt-2 responsive text-center"
            id="tableLetter"
            :item="fetchLetter"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <b-thead class="text-center">
              <b-th> Nome do Remetente </b-th>
              <b-th> Qtd. Cartas </b-th>
              <b-th> Valor </b-th>
              <b-th> Status </b-th>
              <b-th> Data de envio </b-th>
              <b-th> Recibo </b-th>
              <b-th> Ação </b-th>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in letters">
                <b-td> {{ tr.tenant.company_name }} </b-td>
                <b-td> {{ tr.amount }} </b-td>
                <b-td>R$ 500,00</b-td>
                <b-td>
                  <b-badge variant="success" class="badge-glow" v-if="tr.sent_at != null">
                    Enviado
                  </b-badge>
                  <b-badge variant="danger" class="badge-glow" v-if="tr.sent_at == null">
                    Não enviado
                  </b-badge>
                </b-td>
                <b-td v-if="tr.sent_at != null" class="text-center">
                   {{ tr.sent_at | dateFormat }} 
                </b-td>
                <b-td v-else class="text-center">
                   -
                </b-td>
                <b-td class="text-center">
                  <b-button
                    v-if="!tr.receipt"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="tr.receipt"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="tr.receipt"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                </b-td>
                <b-td>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-warning"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-b-modal.modal-delete-tenants
                    href="tr.file_name"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="tableLetter"
          class="mt-1"
        />
      </div>

      <!-- <div v-show="showCreate">
        <div class="demo-vertical-spacing mt-1">
          <b-row>
            <b-col md="4" xl="4" class="p-1">
              <label> Nome do cliente </label> -->
              <!-- <b-form-select
                text-field="company_name"
                value-field="id"
                :options="tenants"
                id="tenant"
              /> -->
              <!-- <b-form-select
                text-field="company_name"
                value-field="id"
                v-model="tenant_id"
                :options="tenants"
                id="tenant"
                
              />
            </b-col>
            <b-col md="2" xl="2" class="p-1">
              <label> Quantidade </label>
              <b-form-input placeholder="Quantidade" type="number" id="name" />
            </b-col>
            <b-col md="2" xl="2" class="p-1">
              <label> Valor </label>
              <b-form-input
                placeholder="Valor de envio "
                type="number"
                id="name"
              />
            </b-col>
            <b-col md="3" xl="3" class="p-1">
              <label> Recibo </label>
              <b-form-file placeholder="Anexar recibo" />
            </b-col>
          </b-row>

          <b-row class="p-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
            >
              Cadastrar
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              type="reset"
              @click="showCreate = false"
            >
              Cancelar
            </b-button>
          </b-row>
        </div>
      </div> -->
    </div>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      tenant_id: null,

      isLoading: true,

      search: null,

      showSearch: false,

      showCreate: false,
    };
  },
  computed: {
    ...mapState("tenants", ["tenants", "pagination"]),
    ...mapState("letters", ["letters", "pagination"]),
  },
  methods: {
    ...mapActions("tenants", ["fetchTenants"]),
    fetchLetter(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("letters/fetchLetter", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchLetter(true, value);
    },
  },
  created() {
    this.fetchLetter();
    this.fetchTenants({
      pagination: false,
    });
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchLetter(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
